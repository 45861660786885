import { categoryModule } from './store/category'
import { StorefrontModule } from '@vue-storefront/core/lib/modules';

export const CatalogNextModule: StorefrontModule = function ({ store }, fromCache) {
  if (fromCache?.['category-next']) {
    categoryModule.state = fromCache['category-next']
  }

  // @ts-ignore
  store.registerModule('category-next', categoryModule)
}
