import { urlStore } from './store'
import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { beforeEachGuard } from './router/beforeEach'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import { routerHelper } from '@vue-storefront/core/helpers';
import { Route } from 'vue-router';
import { compareRegions } from '$modules/shipping/utils/region-url';
import { setCurrentRegion } from 'theme/store/checkout/helpers';

export const cacheStorage = StorageManager.init('url')

export const UrlModule: StorefrontModule = function ({ store, router }, fromCache) {
  if (fromCache?.url) {
    urlStore.state = fromCache.url
  }

  store.registerModule('url', urlStore)

  router.beforeEach((to: Route, from: Route, next) => {
    if (!routerHelper.popStateDetected || !to?.path || !from?.path) {
      return beforeEachGuard(to, from, next)
    }

    const region = compareRegions(from?.path, to?.path)

    if (region) setCurrentRegion(region)

    return beforeEachGuard(to, from, next)
  })
}
