import { breadcrumbsStore } from './store'
import { StorefrontModule } from '@vue-storefront/core/lib/modules'

export const BreadcrumbsModule: StorefrontModule = function ({ store }, fromCache) {
  if (fromCache?.breadcrumbs) {
    breadcrumbsStore.state = fromCache?.breadcrumbs
  }

  store.registerModule('breadcrumbs', breadcrumbsStore)
}
