import { notificationStore } from './store'
import { StorefrontModule } from '@vue-storefront/core/lib/modules';

export const NotificationModule: StorefrontModule = function ({ store }, fromCache) {
  if (fromCache?.notification) {
    notificationStore.state = fromCache.notification
  }

  store.registerModule('notification', notificationStore)
}
